import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import {Container, Grid, Tableau, SubTitle, SubTitleSep, TarifLibelle, TarifPrice, GridWrapNoPad} from '../styles/global';
import styled from "styled-components"
import listePrix from '../downloads/TARIFS_avrilaout2023.pdf'

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


const Thumbs = styled(Img) `
  margin: 20px 0;
`

const LinkB = styled.a `
      color: #000;
`
const Styler = styled.div `
  text-transform:uppercase;
  font-weight: bold;
  margin-top: 1em;
`
const Separator = styled.span `
  width: 3em;
  display:inline-block;
`
const StylerSeparator = styled(Separator) `
  width: 4em;
`
const Prix = styled.span `
  width: 2em;
  display:inline-block;
`
const StylerContainer = styled.div `
`
const Bubble = styled.div `
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto Slab",sans-serif;
  position: relative;
  display: inline-block;
  padding: 20px;
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  outline-offset: -7;
  background-color: #000;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin-left: 20px;
  text-align:center;
  color: #fff;
  text-decoration:none;
  margin-top: 15em;
  right: 0;
  a {
    color: #fff;
  }
  &.active {
      color: #FFFF00;
  }
  &:hover {
      color: #FFFF00;
      a {
        color: #FFFF00
      }
    }
`
const NavDecalTxt = styled.span`
    position: absolute;
    left: 0;
    line-height: 1.1em;
    display: inline-block;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    width: 90%;
    padding: 0 6%;
    `
// markup
const NousPage = () => {


   const data = useStaticQuery(graphql`
  query {
    image1: file(relativePath: { eq: "tarifs_1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "tarifs_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }   
    }
  }
`)
/*
  const data = useStaticQuery(graphql`
     {
        file(relativePath: { eq: "salon_1.jpg" }) {
        childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        
      }
    }
  `)
*/
  return (
    <Layout>
    <main style={pageStyles}>
      <Container>
        <title>Tarifs – Tête à Clak</title>
        <Grid className={'inverse'}>
            <div className={'grid-12 col gauche'}>
              <Thumbs fluid={data.image1.childImageSharp.fluid} alt="." />
              <Tableau className={'grid-13 col'}>
                <SubTitle>Le bon cadeau</SubTitle>
                <p>Vous pouvez offrir un bon cadeau, du montant que vous désirez et faire plaisir en une ou plusieurs fois à la personne de votre choix.</p>
              </Tableau>
              <Thumbs fluid={data.image2.childImageSharp.fluid} alt="." />
            </div>
            <div className={'grid-12 col droite'}>
              <SubTitle>Femme</SubTitle>
              <h3>Cheveux _ courts <small>(niveau oreilles)</small></h3>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>brushing soin</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>54.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe brushing</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>90.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>74.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur complète</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>90.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coloration semi-permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>74.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>nettoyage couleur</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>74.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>mèches / balayage</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>110.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>air touch (sur devis)</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>130.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>création de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>15.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>retouche de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>5.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>104.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration complète</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>140.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>100.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>lissage au tanin</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>150.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage jour</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>80.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage soirée</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>100.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>crêpage de chignon <small>(sur cheveux propres, création de chignon)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>2.-/ mn</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>reine d’un jour <small>(forfait mariage)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>380.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}> > 2ème essai</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>120.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>consultation capillaire</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>50.-/ 30mn</TarifPrice>
              </GridWrapNoPad>

              <h3>Cheveux _ mi-longs <small>(de la mâchoire aux clavicules)</small></h3>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>brushing soin</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>64.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe brushing</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>114.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>74.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur complète</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>114.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coloration semi-permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>84.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>nettoyage couleur</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>84.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>mèches / balayage</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>140.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>air touch (sur devis)</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>130.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>création de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>15.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>retouche de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>5.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>104.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration complète</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>150.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>130.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>lissage au tanin</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>150.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage jour</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>80.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage soirée</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>100.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>crêpage de chignon <small>(sur cheveux propres, création de chignon)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>2.-/ mn</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>reine d’un jour <small>(forfait mariage)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>380.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}> > 2ème essai</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>120.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>consultation capillaire</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>50.-/ 30mn</TarifPrice>
              </GridWrapNoPad>

              <h3>Cheveux _ longs <small>(des clavicules aux épaules)</small></h3>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>brushing soin</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>74.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe brushing</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>119.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>74.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur complète</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>119.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coloration semi-permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>94.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>nettoyage couleur</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>94.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>mèches / balayage</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>160.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>air touch (sur devis)</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>130.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>création de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>15.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>retouche de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>5.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>104.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration complète</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>170.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>160.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>lissage au tanin</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>150.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage jour</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>80.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage soirée</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>100.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>crêpage de chignon <small>(sur cheveux propres, création de chignon)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>2.-/ mn</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>reine d’un jour <small>(forfait mariage)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>380.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}> > 2ème essai</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>120.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>consultation capillaire</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>50.-/ 30mn</TarifPrice>
              </GridWrapNoPad>

              <h3>Cheveux _ très longs <small>(qui dépasse les omoplates)</small></h3>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>brushing soin</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>84.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe brushing</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>154.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>74.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur complète</TarifLibelle>  <TarifPrice className={'grid-tarifs col'}>154.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coloration semi-permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>104.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>nettoyage couleur</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>114.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>mèches / balayage</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>180.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>air touch (sur devis)</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>130.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>création de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>15.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>retouche de frange</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>5.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration racine</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>104.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>décoloration complète</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>230.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>permanente</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>210.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>lissage au tanin</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>150.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage jour</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>80.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>maquillage soirée</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>100.-/ h</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>crêpage de chignon <small>(sur cheveux propres, création de chignon)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>2.-/ mn</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>reine d’un jour <small>(forfait mariage)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>380.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}> > 2ème essai</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>120.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>consultation capillaire</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>50.-/ 30mn</TarifPrice>
              </GridWrapNoPad>
              <SubTitleSep>Soins</SubTitleSep>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>soin Tokyo</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>dès 100.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>soin Epres <small>> répare les ponts de souffre</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>dès 20.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>Soin Oribay <small>> soin profond</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>50.- la dose</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>herbs and clay peeling <small>>nettoyant en profondeur du cuir chevelu</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>dès 40.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>sebum balance pour cuir chevelu gras</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>dès 50.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>soothing > cuir chevelu sensible</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>dès 55.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>pellicule purifying <small>> pour pellicules grassses & sèches</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>dès 60.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>Hydro Dhara <small>> soin ayurvédique contre l’hypertension. Energisant, relaxant, anti-oxydant aux huiles essentielles & hydrolats pour une meilleure élasticité des cheveux</small></TarifLibelle> 
                <TarifPrice className={'grid-tarifs col'}>dès 35.-</TarifPrice>
              </GridWrapNoPad>
  
              <SubTitleSep>Homme</SubTitleSep>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>coupe tondeuse</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>45.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe, séchage <small>(tondeuse et ciseaux)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>55.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe, séchage <small>(uniquement ciseaux)</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>63.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe cheveux longs</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>72.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe + mise en forme de la barbe</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>88.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe cheveux longs + mise en forme de la barbe</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>93.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>mise en forme de la barbe</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>38.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>rasage complet de la barbe</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>55.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>mèches</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>80.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>couleur</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>60.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>soin express du visage</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>55.-</TarifPrice>
              </GridWrapNoPad>
              <SubTitleSep>Epilation</SubTitleSep>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>épilation au fil</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>15.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>épilation à la cire nez</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>10.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>épilation à la cire oreilles</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>20.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>épilation à la cire nez + oreilles</TarifLibelle> <TarifPrice className={'grid-tarifs col'}>25.-</TarifPrice>

              </GridWrapNoPad>
              <SubTitleSep>ADO</SubTitleSep>
              <GridWrapNoPad>
                <TarifLibelle className={'grid-libelle col'}>coupe “PETIT “ tête à clak <small>de 10 à 15 ans</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>45.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}>coupe “PETITE” tête à clak <br /><small>jusqu’à 15 ans</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}><br />75.-</TarifPrice>
                <TarifLibelle className={'grid-libelle col'}><small>jusqu’à 20 ans</small></TarifLibelle> <TarifPrice className={'grid-tarifs col'}>100.-</TarifPrice>
              </GridWrapNoPad><br />
            </div>
        </Grid>
        </Container>
    </main>
    </Layout>
  )
}

export default NousPage
